import Vue from 'vue'
import VueRouter from 'vue-router'
// eslint-disable-next-line import/no-cycle
import superAdmin from '@/router/routes/super-admin'
// eslint-disable-next-line import/no-cycle
import admin from '@/router/routes/admin'
// eslint-disable-next-line import/no-cycle
import agent from '@/router/routes/agent'
// eslint-disable-next-line import/no-cycle
import signataire from '@/router/routes/signataire'
// eslint-disable-next-line import/no-cycle
import utilisateur from '@/router/routes/utilisateur'
// eslint-disable-next-line import/no-cycle
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...superAdmin,
    ...admin,
    ...agent,
    ...signataire,
    ...utilisateur,

    {
      path: '/',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      beforeEnter: (to, from, next) => {
        if (store.state.authStore.token !== null) {
          const data = JSON.parse(store.state.authStore.user)
          if (data && data.role === 'SUPER-ADMINISTRATEUR') next({ name: 'espace-super-admin.dashboard' })
          else if (data && data.role === 'ADMINISTRATEUR') next({ name: 'espace-admin.dashboard' })
          else if (data && data.role === 'SIGNATAIRE') next({ name: 'espace-signataire.dashboard' })
          else if (data && data.role === 'AGENT VERIFICATEUR') next({ name: 'espace-agent.dashboard' })
          else if (data && data.role === 'UTILISATEUR') next({ name: 'espace-utilisateur.dashboard' })
          else next()
        } else {
          next()
        }
      },
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/not-authorized',
      name: 'misc-not-authorized',
      component: () => import('@/views/error/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/waiting-page',
      name: 'waiting-page',
      component: () => import('@/views/error/WaitingPage.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
router.beforeEach((to, from, next) => {
  // to and from are both route objects. must call `next`.
  if (to.meta.requiresAuth && store.state.authStore.token === null) {
    next({ name: 'login' })
  } else {
    next()
  }
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
