// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default [

  // *===============================================---*
  // *--------- ROUTES ADMINISTRATEURS  ---------------------------------------*
  // *===============================================---*
  {
    path: '/espace-admin',
    redirect: '/espace-admin/dashboard',
    component: () => import('@/layouts/espace-admin/AdminLayout.vue'),
    beforeEnter: (to, from, next) => {
      const data = JSON.parse(store.state.authStore.user)
      if (data.role === 'ADMINISTRATEUR') next()
      else next({ name: 'misc-not-authorized' })
    },
    meta: {
      layout: 'vertical',
      requiresAuth: true,
    },
    children: [
      // Dashboard
      {
        path: '/espace-admin/dashboard',
        name: 'espace-admin.dashboard',

        component: () => import('@/views/espace-admin/Home.vue'),

        meta: {
          pageTitle: 'Tableau de bord',
          requiresAuth: true,

          breadcrumb: [
            { text: 'Tableau de bord', active: true },
          ],
        },
      },
      // Liste des Certificats
      {
        path: '/espace-admin/liste-certificats',
        name: 'espace-admin.list-certificats',

        component: () => import('@/views/espace-admin/gestion-certificat/Index.vue'),

        meta: {
          pageTitle: 'Liste des certificats',
          requiresAuth: true,

          breadcrumb: [
            { text: 'Liste des certificats', active: true },
          ],
        },
      },
      // Ajouter un  certificat
      {
        path: '/espace-admin/ajouter-certificat',
        name: 'espace-admin.ajouter-certificat',

        component: () => import('@/views/espace-admin/gestion-certificat/AjouterCertificat.vue'),

        meta: {
          pageTitle: 'Création d\'un certificat',
          requiresAuth: true,

          breadcrumb: [
            { text: 'Création d\'un certificat', active: true },
          ],
        },
      },
      // Liste du Personnel
      {
        path: '/espace-admin/liste-personnels',
        name: 'espace-admin.list-personnels',

        component: () => import('@/views/espace-admin/gestion-personnel/Index.vue'),

        meta: {
          pageTitle: 'Liste du personnel',
          requiresAuth: true,

          breadcrumb: [
            { text: 'Liste du personnel', active: true },
          ],
        },
      },
      // Liste des Types de documents
      {
        path: '/espace-admin/liste-types-docs',
        name: 'espace-admin.list-types-docs',

        component: () => import('@/views/espace-admin/gestion-type-doc/Index.vue'),

        meta: {
          pageTitle: 'Liste des types de pièce',
          requiresAuth: true,

          breadcrumb: [
            { text: 'Liste des types de pièce', active: true },
          ],
        },
      },
    ],
  },
]
