// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default [

  // *===============================================---*
  // *--------- ROUTES Super-ADMINISTRATEURS  ---------------------------------------*
  // *===============================================---*
  {
    path: '/espace-super-admin',
    redirect: '/espace-super-admin/dashboard',
    component: () => import('@/layouts/espace-super-admin/SuperAdminLayout.vue'),
    beforeEnter: (to, from, next) => {
      const data = JSON.parse(store.state.authStore.user)
      if (data.role === 'SUPER-ADMINISTRATEUR') next()
      else next({ name: 'misc-not-authorized' })
    },
    meta: {
      layout: 'vertical',
      requiresAuth: true,
    },
    children: [
      // Dashboard
      {
        path: '/espace-super-admin/dashboard',
        name: 'espace-super-admin.dashboard',

        component: () => import('@/views/espace-super-admin/Home.vue'),

        meta: {
          pageTitle: 'Tableau de bord',
          requiresAuth: true,

          breadcrumb: [
            { text: 'Tableau de bord', active: true },
          ],
        },
      },
      // Liste des Mairies
      {
        path: '/espace-super-admin/liste-mairies',
        name: 'espace-super-admin.list-mairies',

        component: () => import('@/views/espace-super-admin/gestion-mairie/Index.vue'),

        meta: {
          pageTitle: 'Liste des mairies',
          requiresAuth: true,

          breadcrumb: [
            { text: 'Liste des mairies', active: true },
          ],
        },
      },
      // Details  Mairie
      {
        path: '/espace-super-admin/:slug/detail-mairie',
        name: 'espace-super-admin.detail-mairie',

        component: () => import('@/views/espace-super-admin/gestion-mairie/DetailMairie.vue'),

        meta: {
          pageTitle: 'Détail Mairie',
          requiresAuth: true,

          breadcrumb: [
            { text: 'Détail Mairie', active: true },
          ],
        },
      },
      // Liste des Utilisateurs
      {
        path: '/espace-super-admin/liste-utilisateurs',
        name: 'espace-super-admin.list-utilisateurs',

        component: () => import('@/views/espace-super-admin/gestion-utilisateur/Index.vue'),

        meta: {
          pageTitle: 'Liste des utilisateurs',
          requiresAuth: true,

          breadcrumb: [
            { text: 'Liste des utilisateurs', active: true },
          ],
        },
      },
    ],
  },
]
