// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default [

  // *===============================================---*
  // *--------- ROUTES SIGNATAIRES  ---------------------------------------*
  // *===============================================---*
  {
    path: '/espace-signataire',
    redirect: '/espace-signataire/dashboard',
    component: () => import('@/layouts/espace-signataire/SignataireLayout.vue'),
    beforeEnter: (to, from, next) => {
      const data = JSON.parse(store.state.authStore.user)
      if (data.role === 'SIGNATAIRE') next()
      else next({ name: 'misc-not-authorized' })
    },
    meta: {
      layout: 'vertical',
      requiresAuth: true,
    },
    children: [
      // Dashboard
      {
        path: '/espace-signataire/dashboard',
        name: 'espace-signataire.dashboard',

        component: () => import('@/views/espace-signataire/Home.vue'),

        meta: {
          pageTitle: 'Tableau de bord',
          requiresAuth: true,

          breadcrumb: [
            { text: 'Tableau de bord', active: true },
          ],
        },
      },
      // Liste des Demandes en Attente de Signature
      {
        path: '/espace-signataire/liste-demandes-en-attente',
        name: 'espace-signataire.list-demandes-en-attente',

        component: () => import('@/views/espace-signataire/gestion-demande/DemandeEnAttente.vue'),

        meta: {
          pageTitle: 'Liste des demandes en attente',
          requiresAuth: true,

          breadcrumb: [
            { text: 'Liste des demandes en attente', active: true },
          ],
        },
      },

      // Liste des Demandes Signées
      {
        path: '/espace-signataire/liste-demandes-signees',
        name: 'espace-signataire.list-demandes-signees',

        component: () => import('@/views/espace-signataire/gestion-demande/DemandeSignee.vue'),

        meta: {
          pageTitle: 'Liste des demandes signées',
          requiresAuth: true,

          breadcrumb: [
            { text: 'Liste des demandes signées', active: true },
          ],
        },
      },
      // Détail de la demande
      {
        path: '/espace-signataire/:id/detail-demande',
        name: 'espace-signataire.detail-demande',

        component: () => import('@/views/espace-signataire/gestion-demande/DetailDemande.vue'),

        meta: {
          pageTitle: 'Détails d\'une demande',
          requiresAuth: true,

          breadcrumb: [
            { text: 'Détails d\'une demande', active: true },
          ],
        },
      },
    ],
  },
]
