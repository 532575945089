// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/no-cycle
import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/services/notification/useAlert'

const { errorToast } = useAlertNotification()

const authStore = {
  state: {
    authenticated: false,
    user: null,
    token: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = JSON.stringify(user)
      state.authenticated = true
    },
    async initializeAuth(state) {
      // Initialiser l'authentification si l'utilisateur était bien connecté
      try {
        const response = await axiosClient.get('/user')
        state.authenticated = true
        state.user = JSON.stringify(response.data)
      } catch (error) {
        state.token = null
        state.authenticated = false
        localStorage.removeItem('vuex')
        state.user = {}
        localStorage.clear()
        errorToast('Session expirée')
        await router.push({ name: 'login' })
      }
    },

  },
}

export default authStore
