/* eslint-disable no-param-reassign */
import axios from 'axios'
import { $themeConfig } from '@themeConfig'

// eslint-disable-next-line import/no-cycle
import store from '@/store'

const { API_URL } = $themeConfig.app

const axiosClient = axios.create({
  baseURL: API_URL,
})

axiosClient.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${store.state.authStore.token}`
  config.headers.withCredentials = true
  return config
})

export default axiosClient
