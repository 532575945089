import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import createPersistedState from 'vuex-persistedstate'
// eslint-disable-next-line import/no-cycle
import authStore from './auth'
import app from './app'

import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState(),
  ],
  modules: {
    app,
    authStore,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
})
