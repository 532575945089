// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default [

  // *===============================================---*
  // *--------- ROUTES AGENTS VERIFICATEURS---------------------------------------*
  // *===============================================---*
  {
    path: '/espace-agent',
    redirect: '/espace-agent/dashboard',
    component: () => import('@/layouts/espace-agent/AgentLayout.vue'),
    beforeEnter: (to, from, next) => {
      const data = JSON.parse(store.state.authStore.user)
      if (data.role === 'AGENT VERIFICATEUR') next()
      else next({ name: 'misc-not-authorized' })
    },
    meta: {
      layout: 'vertical',
      requiresAuth: true,
    },
    children: [
      // Dashboard
      {
        path: '/espace-agent/dashboard',
        name: 'espace-agent.dashboard',

        component: () => import('@/views/espace-agent/Home.vue'),

        meta: {
          pageTitle: 'Tableau de bord',
          requiresAuth: true,

          breadcrumb: [
            { text: 'Tableau de bord', active: true },
          ],
        },
      },
      // Liste des Demandes en attente de vérification
      {
        path: '/espace-agent/liste-demandes',
        name: 'espace-agent.list-demandes',

        component: () => import('@/views/espace-agent/gestion-demande/Index.vue'),

        meta: {
          pageTitle: 'Liste des demandes',
          requiresAuth: true,

          breadcrumb: [
            { text: 'Liste des demandes', active: true },
          ],
        },
      },
      // Détail de la demande
      {
        path: '/espace-agent/:id/detail-demande',
        name: 'espace-agent.detail-demande',

        component: () => import('@/views/espace-agent/gestion-demande/DetailDemande.vue'),

        meta: {
          pageTitle: 'Détails d\'une demande',
          requiresAuth: true,

          breadcrumb: [
            { text: 'Détails d\'une demande', active: true },
          ],
        },
      },
    ],
  },
]
