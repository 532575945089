// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default [

  // *===============================================---*
  // *--------- ROUTES UTILISATEURS  ---------------------------------------*
  // *===============================================---*
  {
    path: '/espace-utilisateur',
    redirect: '/espace-utilisateur/dashboard',
    component: () => import('@/layouts/espace-utilisateur/UtilisateurLayout.vue'),
    beforeEnter: (to, from, next) => {
      const data = JSON.parse(store.state.authStore.user)
      if (data.role === 'UTILISATEUR') next()
      else next({ name: 'misc-not-authorized' })
    },
    meta: {
      layout: 'vertical',
      requiresAuth: true,
    },
    children: [
      // Dashboard
      {
        path: '/espace-utilisateur/dashboard',
        name: 'espace-utilisateur.dashboard',

        component: () => import('@/views/espace-utilisateur/Home.vue'),

        meta: {
          pageTitle: 'Tableau de bord',
          requiresAuth: true,

          breadcrumb: [
            { text: 'Tableau de bord', active: true },
          ],
        },
      },
      // Liste des demandes soumises
      {
        path: '/espace-utilisateur/liste-demandes-soumises',
        name: 'espace-utilisateur.list-demandes-soumises',

        component: () => import('@/views/espace-utilisateur/gestion-demande/DemandeSoumise.vue'),

        meta: {
          pageTitle: 'Liste des demandes soumises',
          requiresAuth: true,

          breadcrumb: [
            { text: 'Liste des demandes soumises', active: true },
          ],
        },
      },
      // Liste des demandes en cours
      {
        path: '/espace-utilisateur/liste-demandes-encours',
        name: 'espace-utilisateur.list-demandes-encours',

        component: () => import('@/views/espace-utilisateur/gestion-demande/DemandeEnCours.vue'),

        meta: {
          pageTitle: 'Liste des demandes en cours',
          requiresAuth: true,

          breadcrumb: [
            { text: 'Liste des demandes en cours', active: true },
          ],
        },
      },
      // Liste des demandes traitées
      {
        path: '/espace-utilisateur/liste-demandes-traitees',
        name: 'espace-utilisateur.list-demandes-traitees',

        component: () => import('@/views/espace-utilisateur/gestion-demande/DemandeTraitee.vue'),

        meta: {
          pageTitle: 'Liste des demandes traitées',
          requiresAuth: true,

          breadcrumb: [
            { text: 'Liste des demandes traitées', active: true },
          ],
        },
      },
      // Liste des demandes rejetées
      {
        path: '/espace-utilisateur/liste-demandes-rejetees',
        name: 'espace-utilisateur.list-demandes-rejetees',

        component: () => import('@/views/espace-utilisateur/gestion-demande/DemandeRejetee.vue'),

        meta: {
          pageTitle: 'Liste des demandes rejetées',
          requiresAuth: true,

          breadcrumb: [
            { text: 'Liste des demandes rejetées', active: true },
          ],
        },
      },
      // Ajouter une  demande
      {
        path: '/espace-utilisateur/faire-demande',
        name: 'espace-utilisateur.faire-demande',

        component: () => import('@/views/espace-utilisateur/gestion-demande/FaireDemande.vue'),

        meta: {
          pageTitle: 'Faire une demande',
          requiresAuth: true,

          breadcrumb: [
            { text: 'Faire une demande', active: true },
          ],
        },
      },
      // Détail de la demande
      {
        path: '/espace-utilisateur/:id/detail-demande',
        name: 'espace-utilisateur.detail-demande',

        component: () => import('@/views/espace-utilisateur/gestion-demande/DetailDemande.vue'),

        meta: {
          pageTitle: 'Détails d\'une demande',
          requiresAuth: true,

          breadcrumb: [
            { text: 'Détails d\'une demande', active: true },
          ],
        },
      },
    ],
  },
]
