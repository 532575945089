import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import { localize } from 'vee-validate'
import fr from 'vee-validate/dist/locale/fr.json'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

// Axios Mock Adapter
import '@/@fake-db/db'

Vue.prototype.configPhoneNumber = {
  countrySelectorLabel: 'Code pays',
  countrySelectorError: 'Choisir un pays',
  phoneNumberLabel: 'Numéro de téléphone',
  example: 'Exemple :',
}
localize('fr', fr)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = true

new Vue({
  router,
  store,
  beforeCreate() {
    if (store.state.authStore.token !== null) store.commit('initializeAuth')
  },
  render: h => h(App),
}).$mount('#app')
